export const HOME_PATH = "/";
export const UiPaths = {
  HOME: "/",
  ABOUT: "/login",
  GUIDE: "/guide",
  FAQ: "/faq",
  APPOINTMENT: "/appointment",
  INNER_APPOINTMENT: "/innerAppointment",
  INNER_ABOUT: "/innerAbout",
  INNER_GUIDE: "/innerGuide",
  INNER_FAQ: "/innerFaq",
  GLOSSARY: "/glossary",
  SITE_UNDER_MAINTENANCE: "/underMaintenance",

  //Module-1
  DASHBOARD: "/dashboard",
  U_DASHBOARD: "/userDashboard",
  WELCOME_SCREEN: "/welcomeScreen",
  MY_PROJECTS: "/userAllProjects",
  USER_PROFILE: "/profile",
  CREATE_PROJECT: "/createProject",
  EDIT_PROJECT: "/editProject",
  TEMPLATE_UPLOAD: "/uploadTemplate",
  RESET_PASSWORD: "/resetPassword",
  QUERY_HEAD_DEFINING: "/queryHeadDefining",
  QUERY_INPUT: "/queryInput",
  QUERY_VALIDATION: "/queryValidation",
  NEED_PROCESSING_1: "/needProcessingStage1",
  QUERY_BUILDER_SCOUTING: "/queryBuilderScouting",

  //Module-2
  LICENSE_FEASIBILITY: "/licenseFeasibility",
  PRODUCT_LICENSING: "/productLicensing",
  ROUTE_TO_MARKET: "/routeToMarket",
  ROUTE_TO_MARKET_MODULE3: "/routeToMarketModule3",
  LICENSE_FEASIBILITY_MODULE_3: "/licenseFeasibilityPlayer",
  PRODUCT_LICENSING_MODULE_3: "/productLicensingModule3",
  COMPETITORS: "/competitors",
  NEED_OPTIMIZATION: "/needOptimization",
  TRADE_OFF: "/tradeOff",
  COMPLEXITY_ASSESSMENT: "/complexityAssessment",
  COMPLEXITY_ASSESSMENT_BREAKER: "/complexityAssessmentBreaker",
  BREAKER_SCREEN: "/breakModule2-3",
  REGULATORY_INFO: "/regulatoryInfo",
  COMPARE_HIGHLIGHTS: "/compareHighlights",
  INNER_COMPARE_HIGHLIGHTS: "/innercompareHighlights",

  //Module-3
  PLAYER_ASSESSMENT: "/pocMain",
  RELEVANCE_OPTIMIZER: "/relevanceOptimizer",
  PLAYER_PROFILE: "/pocProfile",
  PRODUCT_PROFILE: "/productProfile",
  COMPARE_PRODUCTS: "/compareProducts",
  SAVED_POCS: "/savedPOCs",
  SELECTED_POCS: "/selectedPOCs",
  OPPORTUNITY_COMPARISON: "/opportunityComparison",

  //myCollection
  CONTRACTS_LIST: "/contractsList",
  CONTRACT_MIS: "/projectsStatus",
  PROJECT_DETAIL: "/projectDetail",
  MANAGEMENT_SUMMARY: "/managementSummary",
  IP_MAPPING: "/ipMapping",
  VAULT: "/vault",
  TEAM_ASSESSMENT: "/teamAssessment",
  TOP_PLAYERS: "/topPlayers",
  OTHER_RANKINGS: "/otherRankings",
  PARTNER_CONNECT: "/partnerConnect",
  PARTNER_CONNECT_OUTCOME: "/partnerConnectOutcome",
  PROJECTS_COMPARISON: "/projectsComparison",
  IP_CONSENT: "/ipConsent",
  PARTNER_CONSENT: "/partnerConsent",
  IP_PARTNER_BREAKER_SCREEN: "/ipPartnerBreakerScreen",
  VIEW_SCIENTIFIC_ASSESSMENT: "/viewScientificAssessment",
  SCIENTIFIC_ASSESSMENT_CONSENT: "/scientificAssessmentConsent",
  RESEARCH_LEADS: "/researchLeads",
  MANUFACTURER_LEADS: "/manufacturerLeads",

  //Vault
  INNER_FAME_INDEX: "/innerFameIndex",
  INNER_FAME_INDEX_PLAYER: "/innerFameIndexPlayer",
  INNER_FORMULATION_ADJACENCY: "/innerFormulationAdjacency",
  INNER_BENEFIT_ADJACENCY: "/innerBenefitAdjacency",
  INNER_COMPLEXITY: "/innerComplexity",
  INNER_TRADE_OFF: "/innerTradeOff",
  INNER_PLAYER_ASSESSMENT: "/innerPlayerAssessment",
  INNER_PLAYER_PROFILE: "/innerPlayerProfile",
  INNER_PRODUCT_PROFILE: "/innerProductProfile",
  INNER_RELEVANCE_INDEX: "/innerRelevanceIndex",
  INNER_PRODUCT_COMPARATOR: "/innerProductComparison",
  INNER_SAVED_PLAYER_BUCKET: "/innerSavedPlayerBucket",
  INNER_TEAM_ASSESSMENT: "/innerTeamAssessment",
  INNER_PRODUCT_LICENSING: "/innerProductLicensing",
  INNER_FORMULATION_ADJACENCY_PRODUCT_LICENSING:
    "/innerFormulationAdjacencyProductLicensing",
  INNER_BENEFIT_ADJACENCY_PRODUCT_LICENSING:
    "/innerBenefitAdjacencyProductLicensing",
  INNER_MANUFACTURER: "/innerManufacturerLeads",
  INNER_RESEARCH_LEAD: "/innerResearchLeads",
  INNER_SCIENTIFIC_ASSESSMENT: "/innerScientificAssessment",
  INNER_IP: "/innerIpMapping",
  INNER_IP_CONSENT: "/innerIpConsent",
  INNER_IP_PARTNER_BREAKER_SCREEN: "/innerIpPartnerBreakerScreen",
  INNER_SCIENTIFIC_ASSESSMENT_CONSENT: "/innerScientificAssessmentConsent",
  INNER_PARTNER_CONSENT: "/innerPartnerConsent",
  INNER_PARTNER_CONNECT: "/innerPartnerConnect",
  INNER_OPPORTUNITY: "/innerOpportunity",
  INNER_GRAPH: "/innerVaultGraph",

  RESEARCH_OUTCOME_OPP: "/researchOutcomeOpp",
  MANUFACTURER_OUTCOME_OPP: "/manufacturerOutcomeOpp",
  IP_OUTCOME_OPP: "/ipOutcomeOpp",
  SCIENTIFIC_OUTCOME_OPP: "/scientificOutcomeOpp",

  //Contract Expired
  TOP_UP_CONSENT: "/topUpConsent",

  //Insights
  INSIGHTS_BASE: "/openInsights",
  STRATEGIC_BASE: "/openStrategicInsights",

  SCOUTING_MODULE3: "/scoutingModule3", //scouting ui paths
  SAVED_PLAYERS_SCOUTING: "/scoutingSavedPlayers",
  SCOUTING_VALUE_MATRIX: "/scoutingValueMatrix",
  INNER_SCOUTING_MODULE3: "/innerScoutingPlayerGrid",
  INNER_SCOUTING_SAVED_OPPORTUNITIES: "/innerScoutingSavedOpportunities",
  INNER_SCOUTING_VALUE_MATRIX: "/innerScoutingValueMatrix",
  SCOUTING_RELEVANCE: "/scoutingRelevance",
  INNER_SCOUTING_RELEVANCE: "/innerScoutingRelevance",
  SCOUTING_GRAPHS: "/scoutingGraphs",
  INNER_SCOUTING_GRAPHS: "/innerScoutingGraphs",

  QUERY_BUILDER_STRATEGIC: "/startegicQueryInput", //Strategic
  STRATEGIC_MODULE_3_HOME: "/strategicModule3Home",
  STRATEGIC_OPPORTUNITY_PROFILE: "/strategicOppoProfile",
  STRATEGIC_OPPORTUNITY_COMPARATOR: "/strategicOppoComparator",
  STRATEGIC_SAVED_OPPORTUNITIES: "/strategicSavedOpportunities",
  INNER_STRATEGIC_MODULE_3_HOME: "/innerStrategicValueMatrix",
  INNER_STRATEGIC_OPPORTUNITY_PROFILE: "/innerStrategicOppProfile",
  INNER_STRATEGIC_OPPORTUNITY_PROFILE1: "/innerStrategicOppProfile1",
  INNER_STRATEGIC_OPPORTUNITY_COMPARATOR: "/innerStrategicOppComparator",
  INNER_STRATEGIC_SAVED_OPPORTUNITIES: "/innerStrategicSavedOpportunities",

  LANDSCAPE_QUERY_BUILDER: "/queryBuilderLandscape", //Landscape
  LANDSCAPE_MODULE_3_HOME: "/landscapeModule3Home",
  INNER_LANDSCAPE_MODULE_3_HOME: "/innerLandscapeModule3Home",
  FAMILIARITY_LANDSCAPE: "/landscapeFamiliarity",
  INNER_FAMILIARITY_LANDSCAPE: "/innerLandscapeFamiliarity",
  INNER_GRAPH_COMPETITIVE: "/innerVaultGraphCompetitive",
  INNER_ROUTE_TO_MARKET: "/innerRouteToMarket",
  INNER_REGULATORY_INFO: "/innerRegulatoryInfo",

  FORMULA_CHECKER_FORM: "/formulaInputForm", //Formula
  FORMULA_CHECKER_OUTCOME: "/formulaOutcome",
  ROLL_OUT_OUTCOME: "/rollOutOutcome",

  ADHOC_INPUT_SCREEN: "/adhocInputScreen",
  ADHOC_OUTPUT_SCREEN: "/adhocOutputScreen",

  CONSUMER_QUERY_BUILDER: "/consumerQueryBuilder", //Consumer
  CONSUMER_MODULE3: "/consumerModule3",
  CONSUMER_BRAND_ANALYTICS: "/consumerBrands",
  CONSUMER_PRODUCT_GRAPHS: "/consumerProductGraphs",
  CONSUMER_SOCIAL_GRAPHS: "/consumerSocialGraphs",
  INNER_PRODUCT_REVIEW: "/innerConsumerReview",
  INNER_SOCIAL_REVIEW: "/innerConsumerSocial",
  INNER_BRANDS: "/innerConsumerWebAnalytics",
  INNER_CONSUMER_GRAPH: "/innerConsumerGraph",
  INNER_PRODUCT_TRIGGERS: "/innerConsumerTrigger",
  INNER_PRODUCT_TRENDS: "/innerConsumerTrends",
  INNER_SOCIAL_TRIGGERS: "/innerConsumerTriggerSocial",
  INNER_SOCIAL_TRENDS: "/innerConsumerTrendsSocial",
};
