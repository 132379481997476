//npm packages
import React from 'react';
import ReactDOM from 'react-dom';

import * as serviceWorker from './serviceWorker';
import HomeEntryRoute from './Routes/HomeEntryRoute';

ReactDOM.render(
  <React.StrictMode>
    <HomeEntryRoute />
  </React.StrictMode>,
  document.getElementById('root')
);

serviceWorker.unregister();
