import React, { Suspense } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import sessionStorage from "sessionstorage";
import $ from "jquery";

//css
import "../Assets/Css/bootstrap/css/bootstrap.min.css";
import "../Assets/icon/themify-icons/themify-icons.css";
import "../Assets/icon/icofont/css/icofont.css";
import "../Assets/icon/font-awesome/css/font-awesome.min.css";
import "../Assets/Css/font-awesome-n.min.css";
import "../Assets/Css/font-awesome.min.css";
import "../Assets/Css/jquery-ui.css";
import "../Assets/Css/style.css";
import "../Assets/Css/style-.css";
import "../Assets/Css/responsive.css";
import "../Assets/Css/NewCss/styleNew.css";
import "../Assets/Css/wollemi.css";

import { UiPaths } from "../UiPaths";

const lazyRetry = function (componentImport, name) {
  // a function to retry loading a chunk to avoid chunk load error out of date code
  return new Promise((resolve, reject) => {
    const hasRefreshed = JSON.parse(
      // check if the window has already been refreshed
      window.sessionStorage.getItem(`retry${name}-lazy-refreshed`) || "false"
    );

    componentImport()
      .then((component) => {
        // try to import the component
        window.sessionStorage.setItem(`retry${name}-lazy-refreshed`, "false"); // success so reset the refresh
        resolve(component);
      })
      .catch((error) => {
        if (!hasRefreshed) {
          // not been refreshed yet
          window.sessionStorage.setItem(`retry${name}-lazy-refreshed`, "true"); // we are now going to refresh
          return window.location.reload(); // refresh the page
        }
        reject(error); // Default error behaviour as already tried refresh
      });
  });
};

const Login = React.lazy(() =>
  lazyRetry(() => import("../App/Home/Home.js"), "login")
);
const OtherRoutes = React.lazy(() =>
  lazyRetry(() => import("../Routes/OtherRoutes"), "otherRoutes")
);
const ForgetPasswordReset = React.lazy(() =>
  lazyRetry(() => import("../App/Home/ForgetPasswordReset"), "resetPass")
);

export default class HomeEntryRoute extends React.Component {
  componentDidMount() {
    $(document).on("keypress", function (event) {
      if (
        event.target.id === "singleModalSearchIdActive" ||
        event.target.name === "singleModalSearchIdActive"
      ) {
        if (
          event.key.toLowerCase() == "enter" &&
          typeof document._handleDidYouMean === "function"
        ) {
          document._handleDidYouMean();
        }
      } else if (!$(event.target).is("textarea")) {
        if (event.key.toLowerCase() == "enter") {
          event.preventDefault();
        }
      }
    });

    // document.addEventListener('contextmenu', (e) => {//disable right click
    //      e.preventDefault();
    // });
  }

  render() {
    return (
      <Router basename={`${UiPaths.HOME}`}>
        <Suspense
          fallback={
            <div
              className="loader center"
              style={{ backgroundColor: "#432135" }}
            >
              <div className="loader-wolli">
                <img
                  src={process.env.PUBLIC_URL + "/images/mascot.png"}
                  alt="Loading..."
                  width="40px"
                />
                <div className="multi-ripple">
                  <div></div>
                  <div></div>
                </div>
              </div>
            </div>
          }
        >
          <Switch>
            {/*LOGIN-ROUTES*/}
            <Route
              path={`${process.env.PUBLIC_URL}${UiPaths.HOME}`}
              exact
              component={Login}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}${UiPaths.RESET_PASSWORD}`}
              render={(props) => <ForgetPasswordReset {...props} />}
            />
            <Route path="/about.html" exact />
            <Route path="/privacy-guidelines.html" exact />
            <Route path="/security-framework.html" exact />
            <Route path="/terms-of-use.html" exact />
            <Route path="/expertise.html" exact />
            <Route path="/use-cases.html" exact />
            <Route path="/fact-sheet.html" exact />
            <Route path="/know-us-better.html" exact />
            {/*PROTECTED-ROUTES*/}
            <Route
              path={`${process.env.PUBLIC_URL}${UiPaths.HOME}`}
              render={(props) =>
                sessionStorage.getItem("sessionItem") === null ? (
                  <Redirect to={UiPaths.HOME} />
                ) : (
                  <OtherRoutes {...props} />
                )
              }
            />
          </Switch>
        </Suspense>
      </Router>
    );
  }
}
